import React, { useState } from "react"

const Faqs = props => {
  const [faqSelected, setFaqSelected] = useState(null)
  return (
    <div
      id="faq"
      className={`w-full flex flex-col justify-center bg-blue text-dark-blue`}
    >
      <div className="container mx-auto text-center p-10 mt-10">
        <div className={`section-title`} style={{ fontSize: '40px',color:"#020617", paddingTop: "50px" }}>
          Frequently Asked Questions
        </div>
        {props.description && (
          <div className={`section-text color-${props.viewName}`}>
            {props.description}
          </div>
        )}
      </div>
      <div className="container mx-auto p-10" style={{ paddingTop: '10px' }}>
        {props.listOfFaqs &&
          props.listOfFaqs.map((faq, index) => (
            <div key={index} className="m-10">
              <div className="flex justify-between">
                <div>
                  <p className="font-bold">{faq.title}</p>
                  {index === faqSelected && (
                    <p className="mt-2 desc">{faq.description}</p>
                  )}
                </div>
                <div className={`text-xl`}>
                  <i
                    className="fas fa-plus-circle"
                    style={{cursor: "pointer"}}
                    onClick={() =>
                      index !== faqSelected
                        ? setFaqSelected(index)
                        : setFaqSelected(null)
                    }
                  ></i>
                </div>
              </div>
              <hr />
            </div>
          ))}
      </div>
    </div>
  )
}

export default Faqs
